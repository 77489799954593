
  import { Link } from "react-router-dom";
  import {FaUserFriends, FaChalkboard, FaChartLine, FaImages, FaCalendarAlt, FaCar} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Cookies from 'js-cookie';

import React, {useEffect, useState} from 'react';

  export default function Sidebar() {
    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    
      const { t } = useTranslation();
    return (
      
      <div className="sidebar">
        <div className="sidebarWrapper">
          <div className="sidebarMenu">
            <ul className="sidebarList">
            <Link to={`/admin`}className="SidebarLink">
              <li className="sidebarListItem">
                <span className="sidebaricon"><FaChalkboard/></span>
                <span>{t('dashboard')}</span>
              </li>
              </Link>
              <Link to={`/admin/users`} className="SidebarLink">
                <li className="sidebarListItem">
                  <span className="sidebaricon"><FaUserFriends/></span>
                  <span>Profiles</span>
                </li>
                </Link>
                <Link to={`/admin/services`} className="SidebarLink">
                <li className="sidebarListItem">
                <span className="sidebaricon"><FaCar/></span>
                <span>Services</span>
                </li></Link>
                <Link to={`/admin/gallery`} className="SidebarLink">
                  <li className="sidebarListItem">
                    <span className="sidebaricon"><FaImages/></span>
                    <span>{t('gallery')}</span>
                </li></Link>
                <Link to={`/admin/calendar`} className="SidebarLink">
              <li className="sidebarListItem">
              <span className="sidebaricon"><FaCalendarAlt/></span>
                <span>{t('calendar')}</span>
              </li>
              </Link>
              <li className="sidebarListItem">
              <span className="sidebaricon"><FaChartLine/></span>
                <span> {t('reports')} </span>
              </li>
            </ul>
          </div>
         
        </div>
      </div>
    );
  }