import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';
import React, {useEffect, useState} from 'react';

const LoginButton = () => {
  const [language] = useState(Cookies.get('language') || 'en');
  useEffect(() => {
      i18n.changeLanguage(language);
  }, [language])
  
    const { t } = useTranslation();

  const { loginWithRedirect } = useAuth0();

  const { isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
      <button className="login_out" onClick={() => loginWithRedirect()}>{t('login')}</button>
    )
  );
};

export default LoginButton;