import React, { useEffect, useState } from 'react';
import {FaPlusCircle, FaPlus } from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Cookies from 'js-cookie';
import Nav from "./Navbar";
import { useAuth0 } from "@auth0/auth0-react";

function ProfileForm() {
  const [language] = useState(Cookies.get('language') || 'fr');
  useEffect(() => {
      i18n.changeLanguage(language);
  }, [language])
  
  const { user } = useAuth0();
    const { t } = useTranslation();
    const [setEmail] = useState('');
    const email = user ? user.email : '';
    const [address, setAddress] = useState('');
    const [zip, setZip] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [province, setProvince] = useState('');
    const [country, setCountry] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    
  
    const handleOpenModal = () => {
        setModalVisible(true);
      };
    
    const handleCloseModal = () => {
        setModalVisible(false);
        setEmail("");
        setAddress("");
        setZip("");
        setPhoneNumber("");
        setProvince("");
        setCountry("");
      };

    
    const handleSubmit = async (event) => {
      try { 
      event.preventDefault();
        axios.post('https://signatureauto-backend.herokuapp.com/user/addUser', { email, address, zip, phoneNumber, province, country })
          .then((response) => {
            console.log(response);
            window.location = 'https://signatureautoelegance.com/profile';
          })
          .catch((error) => {
            console.error(error);
          });
    
          setModalVisible(false);
    window.location.href = 'https://signatureautoelegance.com/profile';
    } catch (error) {
      console.error(error);
    }
  }

    return (
        
        <div className='user-panel'>
        
        <Nav/>
                <div className='insertbtn1' onClick={handleOpenModal}> 
                   {t('create_profile')}
                   <FaPlusCircle/></div>
                
             

        <Modal show={modalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className='ProfileTitlepopup'>{t('profile_info')}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <form>
      <label className='row my-1'>
       
        <span className="col-4">{t('email_address')} </span>
         <span className="col-1"><input type="text" value={user.email} onChange={(event) => setEmail(event.target.value)} disabled /></span>
        </label>
        <label className='row my-1'>
        <span className="col-4 ">{t('address')} </span>
        <span className="col-1"><input type="text" value={address} onChange={(event) => setAddress(event.target.value)} /></span>
        </label>
        <label className='row my-1'>
        <span className="col-4">{t('zip')}</span>
        <span className="col-1"><input type="text" value={zip} onChange={(event) => setZip(event.target.value)} /></span>
        </label>
        <label className='row my-1'>
        <span className="col-4">{t('phone')}</span>
        <span className="col-1"><input type="text" value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)} /> </span>
        </label>
        <label className='row my-1'>
        <span className="col-4">Province: </span>
        <span className="col-1"><input type="text" value={province} onChange={(event) => setProvince(event.target.value)} /> </span>
        </label>
        <label className='row my-1'>
        <span className="col-4">{t('country')}</span>
        <span className="col-1"><input type="text" value={country} onChange={(event) => setCountry(event.target.value)} /> </span>
      </label>
      </form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-link" onClick={handleSubmit}><FaPlus/></Button>
        </Modal.Footer>
      </Modal>
      
      </div>
      )
};

export default (ProfileForm);
