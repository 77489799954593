import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'js-cookie';
import i18n from '../../i18n'
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';

const LogoutButton = () => {

  const [language] = useState(Cookies.get('language') || 'en');
  useEffect(() => {
      i18n.changeLanguage(language);
  }, [language])
  
    const { t } = useTranslation();

  const { logout } = useAuth0();

  const { isAuthenticated } = useAuth0();

  return (
    isAuthenticated && (
      <button className="login_out" onClick={() => logout({ returnTo: window.location.origin })}>
      {t('logout')}
    </button>
    )
  );
};

export default LogoutButton;