import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import Topbar from "./Navbar";
import Sidebar from "./Sidebar";
import { FaArrowLeft} from 'react-icons/fa';

import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';
import withAdminAccess from './withAdminAccess';
let Service= () =>
{
    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    const { t } = useTranslation();

    const params = useParams();
        

    const [service, setService] = useState([]);
    



    const getService = async () => {
        const response = await fetch(`https://signatureauto-backend.herokuapp.com/services/${params.id}`);
        const FinalData = await response.json();
        setService(FinalData)
    }



    getService();



    return (
        <div className='admin-panel'> 
        <Topbar />  
        <div className='sidebar-container'>
         <Sidebar/>
        </div>
        <div className='admin-panel-main'>
            <div className='mb-5'>
            <Link to="/admin/services" ><FaArrowLeft/></Link>
            </div>
        <React.Fragment>
            { service &&
            <section className="serviceList">
                <div className="list-group">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="list-group">
                            <li className="list-group-item list-group-item-action">
                                    <span ><img src={service.image} className="admin_serviceimg" alt=""/></span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('gallery_name_en')} </span><span className='adminlist'>{service.service_name_en}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('gallery_name_fr')} </span> <span className='adminlist'>{service.service_name_fr}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a1')} </span><span className='adminlist'>{service.p_small_2seater}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a2')} </span><span className='adminlist'>{service.p_coupe}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a3')} </span><span className='adminlist'>{service.p_small_4d_car}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a4')} </span><span className='adminlist'>{service.p_4d_sedan}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a5')} </span><span className='adminlist'>{service.p_small_suv}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a6')} </span><span className='adminlist'>{service.reg_suv}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a7')} </span><span className='adminlist'>{service.p_big_suv}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a8')} </span><span className='adminlist'>{service.p_small_pickup}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a9')} </span><span className='adminlist'>{service.p_mid_pickup}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a10')} </span><span className='adminlist'>{service.p_big_pickup}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a12')} </span><span className='adminlist'>{service.type}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a13')} </span><span className='adminlist'>{service.service_description_fr}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('a14')} </span><span className='adminlist'>{service.service_description_fr}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            }


        </React.Fragment>
        </div>
        </div>
    )
};
export default withAdminAccess(Service);