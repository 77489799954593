import React, { useEffect, useState } from 'react';
import "./Admin.css";
import Topbar from "./Navbar";
import Sidebar from "./Sidebar";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Cookies from 'js-cookie';
import withAdminAccess from './withAdminAccess';

function Users() {
    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    
      const { t } = useTranslation();
      
      
      const [users, setUsers] = useState([]);
      const [filteredUsers, setFilteredUsers] = useState([]);
      const [searchTerm, setSearchTerm] = useState("");
      const [isOpen, setIsOpen] = useState(false);
  
      const getUsers = async () => {
          const response = await fetch('https://signatureauto-backend.herokuapp.com/user');
          const FinalData = await response.json();
          setUsers(FinalData)
          setFilteredUsers(FinalData)
      }
  
      useEffect(() => {
          getUsers();
      }, [])
  
      useEffect(() => {
          setFilteredUsers(
              users.filter(user =>
                  user.email.toLowerCase().includes(searchTerm.toLowerCase())
              )
          );
      }, [searchTerm, users]);
  
     
      return (
        
          <div className='admin-panel'>
              <Topbar />
              <div className='sidebar-container'>
                  <Sidebar />
              </div>
              <div className='admin-panel-main'>
                <div className='row mb-4'>
                  <div className='col-10'>
                  <h3 className='adminTitle'>Profiles</h3>
                  </div>
                  <div className="col-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t('search')}
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
              </div>
  
                  {filteredUsers.map((user, index) => (
                      <div className='serviceList'>
                          <div className="list-group">
                              <ul onClick={() => setIsOpen(index === isOpen ? null : index)}>
                                  <li className="list-group-item list-group-item-action">
                                      <span className='adminlist'>{user.email}</span>
                                    
                                </li>
                                {isOpen === index && 
                                <li className='list-unstyled'>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('address')} </span><span className='adminlist'>{user.address}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>Province: </span> <span className='adminlist'>{user.province}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('country')} </span> <span className='adminlist'>{user.country}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('zip')} </span> <span className='adminlist'>{user.zip}</span>
                                </li>
                                <li className="list-group-item list-group-item-action">
                                <span className='adminlist fw-bold'>{t('phone')} </span> <span className='adminlist'>{user.phoneNumber}</span>
                                </li>
                                </li>
                              }
                            </ul>
                            
                        </div>
                        
                    </div>

                ))} </div>

        </div>
    )

};


export default withAdminAccess(Users);
