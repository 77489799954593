import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {motion} from 'framer-motion';
import {FaPlusCircle} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2';

function ServicesList(){

  const { user} = useAuth0();

  const [language] = useState(Cookies.get('language') || 'fr');
  useEffect(() => {
      i18n.changeLanguage(language);
  }, [language])
  
    const { t } = useTranslation();
  
  
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const [services, setServices] = useState([]);
    

    const getServices = async () => {
        const response = await fetch("https://signatureauto-backend.herokuapp.com/services");
        const FinalData = await response.json();
        setServices(FinalData)
    }



    useEffect(() => {
        getServices();
    }, [])

    const [isOpen2, setIsOpen2] = useState(false);

    const [selectedOption, setSelectedOption] = useState({});

    const handlePriceChange = (index, event) => {
      setSelectedOption({ ...selectedOption, [index]: event.target.value });
    };
    

    const handleAddToCart = (service, index) => {
      if (!user) {
        // Display an error message if the user is not logged in
        Swal.fire({
          icon: 'error',
          title: t('must_be_logged_in'),
        });
        return;
      }
      if (!selectedOption[index]) {
        // Display an error message if a price option is not selected
        Swal.fire({
          icon: 'error',
          title: t('select_price_error'),
        });
        return;
      }
      const { _id } = service;
      axios.put(`https://signatureauto-backend.herokuapp.com/cart/user/${user.email}`, {
        serviceId : _id,
        price: selectedOption[index]
      })
      .then(response => {
        console.log(response);
        Swal.fire(
          t('service_success'),
          '',
          'success' )
      })
      .catch(error => {
          console.error(error);
          Swal.fire({
            icon: 'error',
            title: t('service_fail')})
      });
    };
    

    return (
        <div className='background-1'>
             <div className="title">SERVICES</div>
                   
            <div className='App'>
            <Slider {...settings} className="slider">
                {services.map((service,index)=> (
                    <div className='container' key={index}>
                        <div className='card_item' >
                        <motion.div className='card_inner' >
                            <img src={service.image} className="serviceimg" alt="" onClick={() => setIsOpen2(index === isOpen2 ? null : index)}/>
                            <h1 className="servicename">{i18n.language === 'en' ? service.service_name_en : service.service_name_fr}</h1>
                            <div className="detail-box">
<select className="select_price" value={selectedOption[index]} onChange={(event) => handlePriceChange(index, event)}>
  <option value="" disabled selected>{t('select')}</option>
  <option value={service.p_small_2seater}>${service.p_small_2seater} - {t('1')} </option>
  <option value={service.p_small_4d_car}>${service.p_small_4d_car} - {t('2')} </option>
  <option value={service.p_4d_sedan}> ${service.p_4d_sedan} - {t('3')} </option>
  <option value={service.p_small_suv}>${service.p_small_suv} - {t('4')} </option>
  <option value={service.p_big_suv}>${service.p_big_suv} - {t('5')} </option>
  <option value={service.p_small_pickup}>${service.p_small_pickup} - {t('6')} </option>
  <option value={service.p_mid_pickup}>${service.p_mid_pickup} - {t('7')} </option>
  <option value={service.p_big_pickup}>${service.p_big_pickup} - {t('8')} </option>
</select>


                            </div>
                            {isOpen2 === index && <div className='expanded-content'>
                                <div className='carditem'>{i18n.language === 'en' ? service.service_description_en : service.service_description_fr}</div>
                              </div>}
                              <div className='plusservice'  onClick={() => handleAddToCart(service, index)}>{t('add_serv')}<FaPlusCircle/></div>    
                        </motion.div>
                        </div>
                    </div>
                ))}
                </Slider>
        </div>
        </div>
    

    );
    

}
export default ServicesList;