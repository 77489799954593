import "./Admin.css";
import Topbar from "./Navbar";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';
import React, {useState, useEffect} from 'react';

function Main() {
    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    const { t } = useTranslation();

    return (
        <div className='admin-panel'>
            <Topbar />
            <div className="admin-panel-main">
            <p>{t('access_denied')}</p>
            </div>
           </div>
    )
};

export default Main;