import Navbar from "./Navbar";
import ServiceList from "./ServicesList";
import Gallery from "./Gallery";
import Cookies from 'js-cookie';
import i18n from '../../i18n'
import React, {useEffect, useState} from 'react';
import title from '../../assets/logo-transparent-png.png'
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

function Home() {

  const [language] = useState(Cookies.get('language') || 'fr');
  useEffect(() => {
      i18n.changeLanguage(language);
  }, [language])
  const { t } = useTranslation();


    return (
      <div className='background-1'>
        <Navbar/>
        <img className="title-logo" src={title}  alt="logo"/>
        <div className="home_page">
          <p className="home_p"><div>{t('home_page')} </div><div> {t('services_offered')} </div></p>
        </div>
        <div className="home_page">
        <ServiceList/>
        </div>
        <div className="home_page">
        <Gallery/>
        </div>
        <footer >
        <div>
        <p className="home_p">@2023 Signature Auto Detailing </p>
        </div>
        <div className="home_p mb-4">
          <a
            href="https://instagram.com/signature_auto_elegance?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noopener noreferrer"
            className="links"
          >
            <FontAwesomeIcon icon={faInstagram}  size="2x" />
          </a>
          <a
            href="
            https://www.facebook.com/SignatureAutoDetailling?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
            className="links"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </div>
      </footer>
      </div>
      
    );
  }
  
  export default Home;