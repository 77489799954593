import React, { useEffect } from 'react';
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import AccessDenied from './AccessDenied';
import Topbar from "./Navbar";

const withAdminAccess = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { user } = useAuth0();


    const allowedEmails = React.useMemo(() => ['marinamel123@live.com', 'felix.labrie@icloud.com','mohamadsalameh80@gmail.com', 'brandonlevis84@gmail.com'], []);

    useEffect(() => {
      if (user.email && allowedEmails.includes(user.email)) {
        console.log("User is an admin");
      } else {
        console.log("User is not an admin");
      }
    }, [user, allowedEmails]);
    

    if (user.email && allowedEmails.includes(user.email)) {
      return <WrappedComponent {...props} />;
    } else {
      return <AccessDenied />;
    }
  };

  return withAuthenticationRequired(Wrapper, {
    authenticating: () => 
      <div className='admin-panel'>
        <Topbar />
        <div className="admin-panel-main">
          <p>Loading...</p>
        </div>
      </div>,
    onRedirecting: () => 
      <div className='admin-panel'>
        <Topbar />
        <div className="admin-panel-main">
          <p>Verifying...</p>
        </div>
      </div>
  });
};
export default withAdminAccess;
