import React from "react";
import "./App.css";
import Home from './components/Main/Home';
import AdminServices from "./components/admin/Admin_Services";
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import ProfileUser from "./components/Main/ProfileUser";

import CartList from './components/Main/CartList';
import AppointmentsList from "./components/Main/AppointmentList";


import Service from './components/admin/Service';
import Main from './components/admin/Main';
import GalleryItem from './components/admin/GalleryItem';
import GalleryItems from './components/admin/GalleryItems';
import PaymentSuccess from './components/payment/paymentSuccess';
import Users from "./components/admin/Users";
import ProfileForm from "./components/Main/ProfileForm";
import Calendar from "./components/admin/Calendar";
import ContactUs from "./components/EmailJs/ContactUs";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/contact" element={<h1>Contact Component</h1>}/>
        <Route path="/admin" element={<Main/>}/>­
        <Route path="/admin/services" element={<AdminServices/>}/>
        <Route path="/admin/service/:id" element={<Service/>}/>
        <Route path="/admin/gallery" element={<GalleryItems/>}/>
        <Route path="/admin/gallery/:id" element={<GalleryItem/>}/>
        <Route path="/cart" element={<CartList/>}/>
        <Route path="/payment/success" element={<PaymentSuccess />} />
        <Route path="/profile" element={<ProfileUser/>} />
        <Route path="/admin/users" element={<Users/>}/>
        <Route path="/profileform" element={<ProfileForm/>} />
        <Route path="/appointments" element={<AppointmentsList />} />
        <Route path ="/admin/calendar" element={<Calendar/>}/>
        <Route path="/contactUs" element={<ContactUs />} />
      </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
