import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./Admin.css";
import Topbar from "./Navbar";
import Sidebar from "./Sidebar";
import { FaPencilAlt, FaEye, FaPlusCircle,  FaPlus} from 'react-icons/fa';
import {FaTrashAlt} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';
import withAdminAccess from './withAdminAccess';

function AdminServices() {

  const [language] = useState(Cookies.get('language') || 'fr');
  useEffect(() => {
      i18n.changeLanguage(language);
  }, [language])
  const { t } = useTranslation();
const [service_name_en, setService_name_en] = useState('');
const [service_name_fr, setService_name_fr] = useState('');
const [p_small_2seater, setP_small_2seater] = useState('');
const [p_coupe, setP_coupe] = useState('');
const [p_small_4d_car, setP_small_4d_car] = useState('');
const [p_4d_sedan, setP_4d_sedan] = useState('');
const [p_small_suv, setP_small_suv] = useState('');
const [reg_suv, setReg_suv] = useState('');
const [p_big_suv, setP_big_suv] = useState('');
const [p_small_pickup, setP_small_pickup] = useState('');
const [p_mid_pickup, setP_mid_pickup] = useState('');
const [p_big_pickup, setP_big_pickup] = useState('');
const [image, setImage] = useState('');
const [service_description_en, setService_description_en] = useState('');
const [service_description_fr, setService_description_fr] = useState('');
const [type, setType] = useState('');
const [modalVisible, setModalVisible] = useState(false);
const [updateModalVisible, setUpdateModalVisible] = useState(false);
const [updateService_name_en, setUpdateService_name_en] = useState('');
const [updateService_name_fr, setUpdateService_name_fr] = useState('');
const [updateP_small_2seater, setUpdateP_small_2seater] = useState('');
const [updateP_coupe, setUpdateP_coupe] = useState('');
const [updateP_small_4d_car, setUpdateP_small_4d_car] = useState('');
const [updateP_4d_sedan, setUpdateP_4d_sedan] = useState('');
const [updateP_small_suv, setUpdateP_small_suv] = useState('');
const [updateReg_suv, setUpdateReg_suv] = useState('');
const [itemsState, setItemsState] = useState({});
const [updateP_big_suv, setUpdateP_big_suv] = useState('');
const [updateP_small_pickup, setUpdateP_small_pickup] = useState('');
const [updateP_mid_pickup, setUpdateP_mid_pickup] = useState('');
const [updateP_big_pickup, setUpdateP_big_pickup] = useState('');
const [updateImage, setUpdateImage] = useState('');
const [updateService_description_en, setUpdateService_description_en] = useState('');
const [updateService_description_fr, setUpdateService_description_fr] = useState('');
const [updateType, setUpdateType] = useState('');
const [id, setId] = useState('');
const { getAccessTokenSilently } = useAuth0();
const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setService_name_en("");
    setService_name_fr("");
    setP_small_2seater("");
    setP_coupe("");
    setP_small_4d_car("");
    setP_4d_sedan("");
    setP_small_suv("");
    setReg_suv("");
    setP_big_suv("");
    setP_small_pickup("");
    setP_mid_pickup("");
    setP_big_pickup("");
    setImage("");
    setService_description_en("");
    setService_description_fr("");
    setType("");

  };

  const handleOpenModal1 = (id) => {
    const newItemsState = { ...itemsState };
    newItemsState[id] = { modalVisible1: true };
    setItemsState(newItemsState);
  };

const handleCloseModal1 = (id) => {
    const newItemsState = { ...itemsState };
    newItemsState[id] = { modalVisible1: false };
    setItemsState(newItemsState);
  };


  const handleOpenUpdateModal = (service) => {
    setUpdateModalVisible(true);
    setUpdateService_name_en(service.service_name_en);
    setUpdateService_name_fr(service.service_name_fr);
    setUpdateP_small_2seater(service.p_small_2seater);
    setUpdateP_coupe(service.p_coupe);
    setUpdateP_small_4d_car(service.p_small_4d_car);
    setUpdateP_4d_sedan(service.p_4d_sedan);
    setUpdateP_small_suv(service.p_small_suv);
    setUpdateReg_suv(service.reg_suv);
    setUpdateP_big_suv(service.p_big_suv);
    setUpdateP_small_pickup(service.p_small_pickup);
    setUpdateP_mid_pickup(service.p_mid_pickup);
    setUpdateP_big_pickup(service.p_big_pickup)
    setUpdateImage(service.image);
    setUpdateService_description_en(service.service_description_en);
    setUpdateService_description_fr(service.service_description_fr);
    setUpdateType(service.type);
    setId(service._id);
  };

  const handleCloseUpdateModal = () => {
      setUpdateModalVisible(false);
  };

  const handleUpdateChange = (event) => {
    if(event.target.name === 'service_name_en'){
        setUpdateService_name_en(event.target.value)
    }
    if(event.target.name === 'service_name_fr'){
        setUpdateService_name_fr(event.target.value)
    }
    if(event.target.name === 'p_small_2seater'){
      setUpdateP_small_2seater(event.target.value)
    }
    if(event.target.name === 'p_coupe'){
      setUpdateP_coupe(event.target.value)
    }
    if(event.target.name === 'p_small_4d_car'){
      setUpdateP_small_4d_car(event.target.value)
    }
    if(event.target.name === 'p_4d_sedan'){
      setUpdateP_4d_sedan(event.target.value)
    }
    if(event.target.name === 'p_small_suv'){
      setUpdateP_small_suv(event.target.value)
    }
    if(event.target.name === 'reg_suv'){
      setUpdateReg_suv(event.target.value)
    }
    if(event.target.name === 'p_big_suv'){
      setUpdateP_big_suv(event.target.value)
    }
    if(event.target.name === 'p_small_pickup'){
      setUpdateP_small_pickup(event.target.value)
    }
    if(event.target.name === 'p_mid_pickup'){
      setUpdateP_mid_pickup(event.target.value)
    }
    if(event.target.name === 'p_big_pickup'){
      setUpdateP_big_pickup(event.target.value)
    }
    if(event.target.name === 'image'){
      setUpdateImage(event.target.value)
    }
    if(event.target.name === 'service_description_en'){
      setUpdateService_description_en(event.target.value)
    }
    if(event.target.name === 'service_description_fr'){
      setUpdateService_description_fr(event.target.value)
    }
    if(event.target.name === 'type'){
      setUpdateType(event.target.value)
    }
    if(event.target.name === 'id'){
      setId(event.target.value)
  }
  }

  const handleUpdate = async (event) => {
    event.preventDefault();
    let errorMessage = "";

  if (!service_name_en || !service_name_fr || !p_small_2seater || !p_coupe || !p_small_4d_car || !p_4d_sedan || !p_small_suv || !reg_suv || !p_big_suv || !p_small_pickup || !p_mid_pickup || !p_big_pickup || !image || !service_description_en || !service_description_fr || !type) {
    return alert(t('empty_field'));
  }

  if (isNaN(p_small_2seater) || isNaN(p_coupe) || isNaN(p_small_4d_car) || isNaN(p_4d_sedan) || isNaN(p_small_suv) || isNaN(reg_suv) || isNaN(p_big_suv) || isNaN(p_small_pickup) || isNaN(p_mid_pickup) || isNaN(p_big_pickup)) {
    return alert(t('price_field'));
  }

  if (service_name_en.length > 40) {
    errorMessage += t('50_char_en');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(service_name_en)) {
    errorMessage += t('special_char_en');
  }

  if (service_name_fr.length > 40) {
    errorMessage += t('50_char_fr');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(service_name_fr)) {
    errorMessage += t('special_char_fr');
  }

  if (service_description_en.length > 350) {
    errorMessage += t('50_desc_en');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(service_description_en)) {
    errorMessage += t('special_desc_en');
  }

  if (service_description_fr.length > 350) {
    errorMessage += t('50_desc_fr');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(service_description_fr)) {
    errorMessage += t('special_desc_fr');
  }

  if (type.length > 10) {
    errorMessage += t('50_char_type');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(type)) {
    errorMessage += t('special_char_type');
  }

  if (errorMessage) {
    return alert(errorMessage);
  }

    const token = await getAccessTokenSilently();
    
    axios.put(`https://signatureauto-backend.herokuapp.com/services/${id}`, {
      service_name_en: updateService_name_en, 
      service_name_fr: updateService_name_fr, 
      p_small_2seater: updateP_small_2seater,
      p_coupe: updateP_coupe,
      p_small_4d_car: updateP_small_4d_car,
      p_4d_sedan: updateP_4d_sedan,
      p_small_suv: updateP_small_suv,
      reg_suv: updateReg_suv,
      p_big_suv: updateP_big_suv,
      p_small_pickup: updateP_small_pickup,
      p_mid_pickup: updateP_mid_pickup,
      p_big_pickup: updateP_big_pickup,
      image: updateImage,
      service_description_en: updateService_description_en,
      service_description_fr: updateService_description_fr,
      type: updateType
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        }
    })
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.error(error);
        });

        setTimeout(() => {
          setUpdateModalVisible(false);
          window.location.reload();
        }, 0);
};

const UpdateButton = ({ service, onUpdateClick }) => {
  return (
      <button onClick={() => onUpdateClick(service)} className="updatebtn"><FaPencilAlt /></button>
  );
};

const handleSubmit = async (event) => {
  event.preventDefault();
  let errorMessage = "";

  if (!service_name_en || !service_name_fr || !p_small_2seater || !p_coupe || !p_small_4d_car || !p_4d_sedan || !p_small_suv || !reg_suv || !p_big_suv || !p_small_pickup || !p_mid_pickup || !p_big_pickup || !image || !service_description_en || !service_description_fr || !type) {
    return alert(t('empty_field'));
  }

  if (isNaN(p_small_2seater) || isNaN(p_coupe) || isNaN(p_small_4d_car) || isNaN(p_4d_sedan) || isNaN(p_small_suv) || isNaN(reg_suv) || isNaN(p_big_suv) || isNaN(p_small_pickup) || isNaN(p_mid_pickup) || isNaN(p_big_pickup)) {
    return alert(t('price_field'));
  }

  if (service_name_en.length > 50) {
    errorMessage += t('50_char_en');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(service_name_en)) {
    errorMessage += t('special_char_en');;
  }

  if (service_name_fr.length > 50) {
    errorMessage += t('50_char_fr');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(service_name_fr)) {
    errorMessage += t('special_char_fr');;
  }

  if (service_description_en.length > 50) {
    errorMessage += t('50_desc_en');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(service_description_en)) {
    errorMessage += t('special_desc_en');;
  }

  if (service_description_fr.length > 50) {
    errorMessage += t('50_desc_fr');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(service_description_fr)) {
    errorMessage += t('special_desc_fr');;
  }

  if (type.length > 10) {
    errorMessage += t('50_char_type');
  }else if (!/^[a-zA-Z0-9\s']+$/.test(type)) {
    errorMessage += t('special_char_type');;
  }

  if (errorMessage) {
    return alert(errorMessage);
  }
  const token = await getAccessTokenSilently();
  axios.post('https://signatureauto-backend.herokuapp.com/services/add', { service_name_en,service_name_fr, p_small_2seater,p_coupe, p_small_4d_car,p_4d_sedan,p_small_suv,reg_suv,p_big_suv,p_small_pickup,p_mid_pickup,p_big_pickup,image,service_description_en,service_description_fr,type}, {
    headers: {
      Authorization: `Bearer ${token}`,
      }
  })
    .then((response) => {
      console.log(response);
      window.location = `https://signatureauto-backend.herokuapp.com/admin/service/${response.data.id}`;
    })
    .catch((error) => {
      console.error(error);
    });

  setModalVisible(false);
}

    const [services, setServices] = useState([]);


    const getServices = async () => {
        const response = await fetch('https://signatureauto-backend.herokuapp.com/services');
        const FinalData = await response.json();
        setServices(FinalData)
    }

    useEffect(() => {
        getServices();
    }, [])


    const handleDeleteClick = async serviceId => {
      try {
      const token = await getAccessTokenSilently();
      axios.delete(`https://signatureauto-backend.herokuapp.com/services/${serviceId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        }
      })
        .then(response => {
          // The delete request was successful
          console.log(response);
        })
        .catch(error => {
          // There was an error
          console.error(error);
        });
        window.location.reload()
      } catch (error) {
        console.error(error);
      }
    };

    const DeleteButton = ({ serviceId, onDeleteClick }) => {
      return (
        <button onClick={() => onDeleteClick(serviceId)} className="deletebtn"><FaTrashAlt /></button>
      );
    };

    return (


        <div className='admin-panel'>
            <Topbar />
            <div className='sidebar-container'>
                <Sidebar />
            </div>
            <div className='admin-panel-main'>
              <div className='row mb-4'>
                <div className='col-10'>
                <h3 className='adminTitle'>Services</h3>
                </div>
                <div className='col-2'>
                    <div className='insertbtn' onClick={handleOpenModal}> 
                    <span>{t('add')}</span>
                    <span className='mx-2'><FaPlusCircle/></span></div>
                </div>
            </div>
            <Modal show={modalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('add_service')}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
       <form>
      <label className='row my-1'>
        <span className="col-6">{t('gallery_name_en')} </span>
        <span className="col-1"><input type="text" value={service_name_en} onChange={(event) => setService_name_en(event.target.value)} /></span>
        </label>
        <label className='row my-1'>
        <span className="col-6 ">{t('gallery_name_fr')} </span>
        <span className="col-1"><input type="text" value={service_name_fr} onChange={(event) => setService_name_fr(event.target.value)} /></span>
        </label>
      <label className='row my-1'>
      <span className="col-6">{t('a1')}</span>
      <span className="col-1"><input type="text" value={p_small_2seater} onChange={(event) => setP_small_2seater(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a2')}</span>
      <span className="col-1"><input type="text" value={p_coupe} onChange={(event) => setP_coupe(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a3')}</span>
      <span className="col-1"><input type="text" value={p_small_4d_car} onChange={(event) => setP_small_4d_car(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a4')}</span>
      <span className="col-1"><input type="text" value={p_4d_sedan} onChange={(event) => setP_4d_sedan(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a5')}</span>
      <span className="col-1"><input type="text" value={p_small_suv} onChange={(event) => setP_small_suv(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a6')}</span>
      <span className="col-1"><input type="text" value={reg_suv} onChange={(event) => setReg_suv(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a7')}</span>
      <span className="col-1"><input type="text" value={p_big_suv} onChange={(event) => setP_big_suv(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a8')}</span>
      <span className="col-1"><input type="text" value={p_small_pickup} onChange={(event) => setP_small_pickup(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a9')}</span>
      <span className="col-1"><input type="text" value={p_mid_pickup} onChange={(event) => setP_mid_pickup(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a10')}</span>
      <span className="col-1"><input type="text" value={p_big_pickup} onChange={(event) => setP_big_pickup(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a11')}</span>
      <span className="col-1"><input type="text" value={image} onChange={(event) => setImage(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a12')}</span>
      <span className="col-1"><input type="textarea" value={type} onChange={(event) => setType(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a13')}</span>
      <span className="col-1">
        <textarea value={service_description_en} onChange={(event) => setService_description_en(event.target.value)} /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a14')}</span>
      <span className="col-1">
        <textarea value={service_description_fr} onChange={(event) => setService_description_fr(event.target.value)} />
      </span>
      </label>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-link" onClick={handleSubmit}><FaPlus/></Button>
        </Modal.Footer>
      </Modal>
      <Modal show={updateModalVisible} onHide={handleCloseUpdateModal}>
                <Modal.Header closeButton>
                    <Modal.Title className='adminTitlepopup'>{t('update_service')}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <form>
      <label className='row my-1'>
        <span className="col-6">{t('gallery_name_en')} </span>
        <span className="col-1"><input type="text" value={updateService_name_en} onChange={handleUpdateChange}  name="service_name_en"/></span>
        </label>
        <label className='row my-1'>
        <span className="col-6 ">{t('gallery_name_fr')} </span>
        <span className="col-1"><input type="text" value={updateService_name_fr}  onChange={handleUpdateChange}  name="service_name_fr"/></span>
        </label>
      <label className='row my-1'>
      <span className="col-6">{t('a1')}</span>
      <span className="col-1"><input type="text" value={updateP_small_2seater}  onChange={handleUpdateChange}  name="p_small_2seater"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a2')}</span>
      <span className="col-1"><input type="text" value={updateP_coupe} onChange={handleUpdateChange}  name="p_coupe"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a3')}</span>
      <span className="col-1"><input type="text" value={updateP_small_4d_car}  onChange={handleUpdateChange}  name="p_small_4d_car"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a4')}</span>
      <span className="col-1"><input type="text" value={updateP_4d_sedan}  onChange={handleUpdateChange}  name="p_4d_sedan" /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a5')}</span>
      <span className="col-1"><input type="text" value={updateP_small_suv}  onChange={handleUpdateChange}  name="p_small_suv"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a6')}</span>
      <span className="col-1"><input type="text" value={updateReg_suv} onChange={handleUpdateChange}  name="reg_suv"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a7')}</span>
      <span className="col-1"><input type="text" value={updateP_big_suv}  onChange={handleUpdateChange}  name="p_big_suv"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a8')}</span>
      <span className="col-1"><input type="text" value={updateP_small_pickup}  onChange={handleUpdateChange}  name="p_small_pickup"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a9')}</span>
      <span className="col-1"><input type="text" value={updateP_mid_pickup} onChange={handleUpdateChange}  name="p_mid_pickup"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a10')}</span>
      <span className="col-1"><input type="text" value={updateP_big_pickup} onChange={handleUpdateChange}  name="p_big_pickup"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a11')}</span>
      <span className="col-1"><input type="text" value={updateImage} onChange={handleUpdateChange}  name="image"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a12')}</span>
      <span className="col-1"><input type="textarea" value={updateType} onChange={handleUpdateChange}  name="type" /></span>
      <span className="col-1"><input type="text" value={updateType} onChange={handleUpdateChange}  name="type" /></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a13')}</span>
      <span className="col-1">
        <textarea value={updateService_description_en} onChange={handleUpdateChange}  name="service_description_en"/></span>
      </label>
      <label className='row my-1'>
      <span className="col-6">{t('a14')}</span>
      <span className="col-1">
        <textarea value={updateService_description_fr} onChange={handleUpdateChange}  name="service_description_fr"/>
      </span>
      </label>
          </form>
                </Modal.Body>
                <Modal.Footer>
          <Button variant="btn btn-link" onClick={handleUpdate} className="updatebtn"><FaPencilAlt /></Button>
        </Modal.Footer>
            </Modal>
                {services.map((service) => (
                    <div className='servicelist'>
                        <div className="list-group">
                            <ul>
                                <li className="list-group-item list-group-item-action">
                                    <span className='admin-icons'>
                                    <span className='admin-icon'><Link className="iconLink" to={`/admin/service/${service._id}`}><FaEye /></Link></span>
                                    <span className='admin-icon'><UpdateButton service={service} onUpdateClick={handleOpenUpdateModal} /></span>
                                        <span className='admin-icon'><DeleteButton serviceId={service._id} onDeleteClick={handleOpenModal1} /></span>
                                    </span>
                                    <span className='adminlist'>{service.service_name_en}</span>
                                    <Modal show={itemsState[service._id]?.modalVisible1} onHide={() => handleCloseModal1(service._id)} >
                                             <Modal.Header closeButton>
                                               <Modal.Title className='adminTitlepopup'>{t('delete_service')}</Modal.Title>
                                             </Modal.Header>
                                             <Modal.Body >
                                             <form>
                                           <label className='row my-1'>
                                             <span className="">{t('admin_delete')}</span>
                                             </label>

                                               </form>
                                             </Modal.Body>
                                             <Modal.Footer>
                                               <DeleteButton serviceId={service._id} onDeleteClick={handleDeleteClick} />
                                             </Modal.Footer>
                                       </Modal>
                                </li>
                            </ul>
                        </div>
                    </div>

                ))} </div>

        </div>
    )

};


export default withAdminAccess(AdminServices);