import React, { useState, useEffect } from 'react';
import Nav from './Navbar'
import Modal from 'react-bootstrap/Modal';
import {FaTrashAlt,FaPlusCircle, FaPlus } from 'react-icons/fa';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';

function AppointmentList(props) {
    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    
      const { t } = useTranslation();
    const [setCart] = useState({});
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [length, setLength] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [services] = useState([]);
    const email1 = 'mohamadsalameh80@gmail.com';
    const [appointments, setAppointments] = useState([]);
    const [fetchedAppointments, setFetchedAppointments] = useState(false);
    const handleOpenModal = () => {
        setModalVisible(true);
      };
    
    const handleCloseModal = () => {
        setModalVisible(false);
        setEmail("");
        setDate("");
        setTime("");
        setLength("");
      };
  
    useEffect(() => {
      if (!fetchedAppointments) {
        fetch(`https://signatureauto-backend.herokuapp.com/appointments/user/${email1}`)
          .then(response => response.json())
          .then(data => {
            setAppointments(data);
            setFetchedAppointments(true);
          })
          .catch(error => console.log(error));
      }
    }, [fetchedAppointments]);
      
      const handleDelete = (serviceId) => {
        if (services.length > 0 && window.confirm("Are you sure you want to delete this service from your cart?")) {
            fetch(`https://signatureauto-backend.herokuapp.com/cart/user/${email}/service/${serviceId}`,
            { method: 'DELETE' })
            .then(response => response.json())
            .then(data => setCart(data))
            .catch(error => console.log(error));
        }}

        const handleSubmit = async (event) => {
            try { 
            event.preventDefault();
      
              axios.post('https://signatureauto-backend.herokuapp.com/appointments/add', { email, date, time, length}, {
              })
                .then((response) => {
                  console.log(response);
                  window.location = `https://signatureautoelegance.com/appointments`;

                })
                .catch((error) => {
                  console.error(error);
                });
          
            setModalVisible(false);
            window.location.reload()
          } catch (error) {
            console.error(error);
          }
        };
    return (
        
        <div>
          <Nav />
          <div className="cart-panel">
            <div className="row mb-4">
              <div className="col-11">
                <h3 className="adminTitle">{t('Appointments')}</h3>
              </div>
              <div className='col-2'>
                    <div className='insertbtn' onClick={handleOpenModal}> 
                    <span>Book appointment</span>
                    <span className='mx-2'><FaPlusCircle/></span></div>
                </div>
              <Modal show={modalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className='adminTitlepopup'>{t('add_gallery')}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
        <form>
      <label className='row my-1'>
        <span className="col-4">{t('Email')} </span>
        <span className="col-1"><input type="text" value={email} onChange={(event) => setEmail(event.target.value)} /></span>
        </label>
        <label className='row my-1'>
        <span className="col-4 ">{t('Date')} </span>
        <span className="col-1">
        <DatePicker
        value={date}
          selected={date}
          onChange={(selectedDate) => setDate(selectedDate)}
          dateFormat="MMMM d, yyyy"
          placeholderText={date ? date.toDateString() : 'Select a date'}
        />
        </span>
        </label>
      <label className='row my-1'>
      <span className="col-4">{t('Time')}</span>
      <span className="col-1">
      <select value={time} onChange={(event) => setTime(event.target.value)}>
        <option value="">Select a time</option>
        <option value="09:00">09:00</option>
        <option value="09:30">09:30</option>
        <option value="10:00">10:00</option>
        <option value="10:30">10:30</option>
        <option value="11:00">11:00</option>
        <option value="11:30">11:30</option>
        <option value="12:00">12:00</option>
        <option value="12:30">12:30</option>
        <option value="13:00">13:00</option>
        <option value="13:30">13:30</option>
        <option value="14:00">14:00</option>
        <option value="14:30">14:30</option>
        <option value="15:00">15:00</option>
        <option value="15:30">15:30</option>
        <option value="16:00">16:00</option>
        <option value="16:30">16:30</option>
        <option value="17:00">17:00</option>
        <option value="17:30">17:30</option>
        <option value="18:00">18:00</option>
      </select>
      </span>
      </label>
      <label className='row my-1'>
      <span className="col-4">{t('Length')}</span>
      <span className="col-1"><input type="text" value={length} onChange={(event) => setLength(event.target.value)} /> </span>
      </label>
      
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-link" onClick={handleSubmit}><FaPlus/></Button>
        </Modal.Footer>
      </Modal>
              <div className="col-1">
                <span className="insertbtn">Total: {appointments.length}</span>
              </div>
            </div>
            {appointments.length > 0 && (
              <div className="list-group">
                <ul>
                  {appointments.map((appointment, index) => (
                    <li
                      className="list-group-item list-group-item-action mt-2"
                      key={index}
                    >
                      <div className='row'>
                        <div className='col-3'>
                          <div className='adminlist'>{appointment.date.split("T")[0]}</div>
                        </div>
                        <div className='col-3'>
                          <div className='adminlist'>{appointment.time}</div>
                        </div>
                        <div className='col-3'>
                          <div className='adminlist'>{appointment.length} Hours</div>
                        </div>
                        <div className='col-3'>
                          <div className='admin-icons'>
                            <div className='admin-icon'>
                              <button
                                className="deletebtn"
                                onClick={() => handleDelete(appointment.id)}
                              >
                                <FaTrashAlt />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      );
  };

export default AppointmentList;
