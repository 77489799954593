import React, {useEffect, useState,useRef } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import logo from "../../assets/whitelogo.png";
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n'
import axios from 'axios';
import LoginButton from '../auth/login';
import LogoutButton from '../auth/logout';
import Profile from '../auth/profile';
import { FaEnvelope, FaShoppingCart } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { user} = useAuth0();
  const [cart, setCart] = useState({});
  const numItemsRef = useRef(0);

  const handleClick = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    Cookies.set('language', language, { path: '/' });
  };
  
  useEffect(() => {
    if (user) {
      const email = user.email;
      axios.get(`https://signatureauto-backend.herokuapp.com/cart/user/${email}`)
        .then(response => {
          const data = response.data;
          if (!data || data.length === 0) {
            console.log('cart does not exist');
          } else {
            const numItems = data.items.length;
            numItemsRef.current = numItems;
            setCart(data);
          }
        })
        .catch(error => console.log(error));
    }
  }, [cart,user]);


  return (
    <nav className="navbar">
    <div className="nav-item">
      <Link to="/">
      <img className="logo-nav" src={logo} alt="logo" />
      </Link>
      
    </div>
    <div onClick={handleClick} className="nav-icon">
      {open ? <FiX /> : <FiMenu />}
    </div>
      <ul className={open ? 'nav-links active' : 'nav-links'}>
        
      <li className="nav-item2">
          <Link to="/profile" className="nav-link" onClick={closeMenu}>
            <Profile />
          </Link>
        </li>
        <li className="nav-item">
          <LoginButton />
        </li>
        <li className="nav-item">
          <LogoutButton />
        </li>
        
        <li className="nav-item1">
          <Link to="/cart" className="nav-link" onClick={closeMenu}>
            <FaShoppingCart />
            <span className="badge rounded-pill bg-danger bs-nav-link-padding-x:">{numItemsRef.current}</span>
          </Link>
        </li>
        <div className="language-selector" role="button">
        {t('code')}
        <ul className="language-dropdown">
          <li className="language-option" onClick={() => handleLanguageChange('fr')}>
            {t('french')}
          </li>
          <li className="language-option" onClick={() => handleLanguageChange('en')}>
            {t('english')}
          </li>
        </ul>
      </div>
        <li className="nav-item1">
          <Link to="/contactUs" className="nav-link" onClick={closeMenu}>
            <FaEnvelope/>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
