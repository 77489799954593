import React, { useEffect, useState } from 'react';
import "./Admin.css";
import Topbar from "./Navbar";
import Sidebar from "./Sidebar";

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Cookies from 'js-cookie';
import withAdminAccess from './withAdminAccess';
function Main() {
    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    
      const { t } = useTranslation();
    return (
        <div className='admin-panel'>
            <Topbar />
            <div className='sidebar-container'>
                <Sidebar />
            </div>
            <div className="admin-panel-main">
            <p>{t('admin_welcome')}</p>
            </div>
           </div>
    )
};

export default withAdminAccess(Main);