import React, {useEffect, useState} from 'react';
import whitelogo from "../../assets/whitelogo.png";
import { Link } from "react-router-dom";
import LoginButton from '../auth/login';
import LogoutButton from '../auth/logout';
import Profile from '../auth/profile';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n'
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    //MDBNavbarLink,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
  } from 'mdb-react-ui-kit';

const Nav=()=>{

  const [language] = useState(Cookies.get('language') || 'fr');
useEffect(() => {
    i18n.changeLanguage(language);
}, [language])

  const { t } = useTranslation();

    return (
        <MDBNavbar expand='lg' className='navbar2'>
          <MDBContainer fluid>
            <MDBNavbarBrand > 
              <Link to="/">
                <img className="logo-1" src={whitelogo}  alt="logo"/>
              </Link>
</MDBNavbarBrand>
            
            <MDBNavbarToggler
              aria-controls='navbarSupportedContent'
              aria-expanded='false'
              aria-label='Toggle navigation'
            >
              <MDBIcon icon='bars' fas />
            </MDBNavbarToggler>

              <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
            </MDBNavbarNav>
            <MDBNavbarNav right fullWidth={false} className='mb-2 mb-lg-0'>
            <MDBNavbarItem>
                  <Profile/>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <LoginButton/>
                </MDBNavbarItem>
                <MDBNavbarItem>
                  <LogoutButton/>
                </MDBNavbarItem>
                <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link' role='button'>
                    {t('code')}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                  <MDBDropdownItem link onClick={() => {
                      i18n.changeLanguage('fr');
                      Cookies.set('language', 'fr', { path: '/' });
                    }}>{t('french')}</MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => {
                      i18n.changeLanguage('en');
                      Cookies.set('language', 'en', { path: '/' });
                    }}>{t('english')}</MDBDropdownItem>
                  </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarNav>
          </MDBContainer>
        </MDBNavbar>
      );
      
    }
export default Nav;