import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import Cookies from "js-cookie";
import Nav from "./Navbar";
import { Link } from "react-router-dom";
import axios from 'axios';
import {  Button } from 'react-bootstrap';


const ProfileUser = () => {
    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
      i18n.changeLanguage(language);
    }, [language]);
  
    const { t } = useTranslation();
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [userExists, setUserExists] = useState(false);
    const [province, setProvince] = useState("");
    const [address, setAddress] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [appointments, setAppointments] = useState([]);
    const { loginWithRedirect } = useAuth0();

    const getUsers = useCallback(async () => {
      if (user) {
        const response = await fetch(
          `https://signatureauto-backend.herokuapp.com/user/${user.email}`
        );
        const finalData = await response.json();
        if (finalData) {
          setUserExists(true);
          setProvince(finalData.province);
          setAddress (finalData.address);
          setZip (finalData.zip);
          setCountry(finalData.country);
          setPhoneNumber(finalData.phoneNumber);
        }
      }
    }, [user, setUserExists]);

    const getAppointments = useCallback(async () => {
      if (user) {
        const response = await fetch(
          `https://signatureauto-backend.herokuapp.com/appointments/user/${user.email}`
        );
        const finalData = await response.json();
        if (finalData) {
          setAppointments(finalData);
        }
      }
    }, [user, setAppointments]);
    const cancelAppointment = async (appointmentId) => {
      if (window.confirm(t('apt_cancel'))) {
        try {
          await axios.put(`https://signatureauto-backend.herokuapp.com/appointments/${appointmentId}`);
          // Refresh the list of appointments after cancellation
          getAppointments();
        } catch (error) {
          console.log(error);
          // Handle the error
        }
      }
    };
    
    useEffect(() => {
      getUsers();
      getAppointments();
    }, [getUsers, getAppointments]);
  
    if (isLoading) {
        return  <div className='admin-panel'>
        <div className="admin-panel-main">
        <p>{t('loading')}</p>
        </div>
       </div>;
      }
  

      if (!userExists && isAuthenticated) {
        return  <div className='admin-panel'>
          <Nav />
        <div className="admin-panel-main">
        <Link to="/profileform">
            <p>{t('create_profile')}</p>
          </Link>
        </div>
       </div>;
      }
      const handleSubmit = (event) => {
        event.preventDefault();
    
        axios.put(`https://signatureauto-backend.herokuapp.com/user/${user.email}`, {
          address: address,
          province: province,
          zip: zip,
          country: country,
          phoneNumber: phoneNumber,
        })
          .then(response => {
            console.log(response.data);
            // Update state, show success message, etc.
          })
          .catch(error => {
            console.log(error);
            // Show error message, handle error, etc.
          });
      };

      if (!isAuthenticated) {
        loginWithRedirect();
        return null;
      }
     
return (

      isAuthenticated && (
<div>
  <Nav />
  <div className="cart-panel">
    <div>
      <div>
        <h3 className="profileTitle">{user.email}</h3>
      </div>
      <section className="serviceList1">
        <div className="list-group">
          <div className="row">
            <div className="col-md-12">
            <form onSubmit={handleSubmit}>
                <ul className="list-group">
                  <li className="list-unstyled">
                    <li className="list-group-item list-group-item-action">
                      <label htmlFor="address" className="adminlist fw-bold">
                        {t('address')}
                      </label>{" "}
                      <input type="text" id="address" name="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </li>
                    <li className="list-group-item list-group-item-action">
                      <label htmlFor="province" className="adminlist fw-bold">
                        Province:
                      </label>{" "}
                      <input type="text" id="province" name="province" value={province} onChange={(e) => setProvince(e.target.value)} />
                    </li>
                    <li className="list-group-item list-group-item-action">
                      <label htmlFor="zip" className="adminlist fw-bold">
                        {t('zip')}
                      </label>{" "}
                      <input type="text" id="zip" name="zip" value={zip} onChange={(e) => setZip(e.target.value)} />
                    </li>
                    <li className="list-group-item list-group-item-action">
                      <label htmlFor="country" className="adminlist fw-bold">
                        {t('country')}
                      </label>{" "}
                      <input type="text" id="country" name="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                    </li>
                    <li className="list-group-item list-group-item-action">
                      <label htmlFor="phoneNumber" className="adminlist fw-bold">
                        {t('phone')}
                      </label>{" "}
                      <input type="text" id="phoneNumber" name="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </li>
                  </li>
                </ul>
                <div className="row mt-2 mb-2">
                  <div className="col-2 mb-2">
                <Button variant="info" type="submit">{t('update')}</Button>
                </div>
                </div>
              </form>
            </div>
            <section>
          <div className="list-group">
            <div className="row">
              <div className="col-md-12">
                <h2 className="adminTitle">{t('appointments')}</h2>
                {appointments.length > 0 ? (
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>{t('time')}</th>
                        <th>{t('len')}</th>
                        <th>{t('Status')}</th>
                        <th>{t('paid')}</th>
                        <th>{t('location')}</th>
                        <th>{t('cancel')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appointments.map((appointment, index) => (
                        <tr key={index}>
                          <td>{appointment.date.split("T")[0]}</td>
                          <td>{appointment.time}</td>
                          <td>{appointment.length} {t('hrs')}</td>
                          <td>{appointment.status}</td>
                          <td>{appointment.paid ? t('yes') : t('no')}</td>
                          <td>{appointment.location}</td>
                          {appointment.status === 'upcoming' &&
                            <td>  <Button variant="danger" onClick={() => cancelAppointment(appointment._id)}>
                            {t('cancel')}
                          </Button></td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>{t('no_appointments')}</p>
                )}
              </div>
            </div>
          </div>
        </section>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

      )
    );
  };
  
  export default ProfileUser;
  