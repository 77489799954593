import React, { useState, useEffect } from 'react';
import Nav from './Navbar'
import {FaTrashAlt} from 'react-icons/fa';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';
import Swal from 'sweetalert2';
import moment from 'moment';

function CartList() {

  const { user, isLoading} = useAuth0();

    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    
    
    const { t } = useTranslation();
    const [cart, setCart] = useState({});
    const [services, setServices] = useState([]);
    const [fetchedItems, setFetchedItems] = useState(false);
    const [total, setTotal] = useState(0);
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [location, setLocation] = useState("");
  
    const [length, setLength] = useState("");

    const { loginWithRedirect } = useAuth0();
    const [checkoutOption, setCheckoutOption] = useState("paying"); 
    
    
    useEffect(() => {
      if (user) {
        const email = user.email;
        fetch(`https://signatureauto-backend.herokuapp.com/cart/user/${email}`)
        .then(response => response.json())
        .then(data => {
          if (!data || data.length === 0) {
        console.log('cart does not exist');
        fetch("https://signatureauto-backend.herokuapp.com/cart", {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json'
        },
        body: JSON.stringify({
        email: email
        })
        })
        .then(res => res.json())
        .then(data => {
        setCart(data);
        setFetchedItems(true);
        })
        .catch(error => console.log(error));
        } else {
        setCart(data);
        setFetchedItems(true);
        }
        })
        .catch(error => console.log(error));
      }
      }, [user]);
      
      
      useEffect(() => {
        if (fetchedItems && cart.items) {
          cart.items.forEach(item => {
            fetch(`https://signatureauto-backend.herokuapp.com/services/${item.serviceId}`)
              .then(response => response.json())
              .then(data => {
                setServices(prevServices => [...prevServices, data]);
              })
              .catch(error => console.log(error));
          });
        }
      }, [fetchedItems, cart.items]);
      
      useEffect(() => {
        let total = 0;
        let length = 0;
        if (fetchedItems && cart.items) {
          cart.items.forEach((item, index) => {
            total += item.price;
            if (services.length > index) {
              length += services[index].length;
            }
          });
          if (location === "home") {
            total += 20;
            length += 0.5;
          }
          setTotal(total);
          setLength(length);
        }
      }, [fetchedItems, cart.items, services, location]);
      
    
      
      
  const emptyCart = (email) => {
    fetch(`https://signatureauto-backend.herokuapp.com/cart/user/${email}`, { method: 'DELETE' })
      .then(response => response.json())
      .then(data => {
        setCart(data);
        setServices([]);
        setTotal(0);
        window.location.reload();
      })
      .catch(error => console.log(error));
  };

const handleEmptyCart = () => {
  if (window.confirm(t('cart_confirmation_empty'))) {
    emptyCart(user.email);
  }
}

const handleDelete = (serviceId) => {
        if (services.length > 0 && window.confirm(t('cart_confirmation_delete'))) {
          const email = user.email;
            fetch(`https://signatureauto-backend.herokuapp.com/cart/user/${email}/service/${serviceId}`,
            { method: 'DELETE' })
            .then(response => response.json())
            .then(data => {
              setCart(data);
              let newTotal = 0;
              services.forEach(service => {
                if (service.id !== serviceId && !isNaN(Number(service.price)) && service.price) {
                  newTotal += Number(service.price);
                }
              });
              setTotal(newTotal);
              window.location.reload();
            })
            .catch(error => console.log(error));
        }
    }
    const handleCreateAppointment = () => {
      // Check if appointment can be booked
      const now = moment();
      const bookingDate = moment(`${date} ${time}`, "YYYY-MM-DD HH:mm");
      const bookingLimit = moment().add(1, "month");
      const isWeekday = bookingDate.isoWeekday() >= 1 && bookingDate.isoWeekday() <= 5;
      const isWorkingHours = bookingDate.hour() >= 8 && bookingDate.hour() < 19;
    
      if (bookingDate.isBefore(now)) {
        return Swal.fire({
          title: t('invalid_datetime'),
          text:t('date_passed'),
          icon: 'error',
        });
      }
      if (bookingDate.isAfter(bookingLimit)) {
        return Swal.fire({
          title: t('invalid_datetime'),
          text: t('one_month'),
          icon: 'error',
        });
      }
      if (!isWeekday) {
        return Swal.fire({
          title: t('invalid_datetime'),
          text: t('mon-fri'),
          icon: 'error',
        });
      }
      if (!isWorkingHours) {
        return Swal.fire({
          title: t('invalid_datetime'),
          text: t('8-7'),
          icon: 'error',
        });
      }
      if (length === 0) {
        return Swal.fire({
        title: t('invalid_service'),
        text: t("no_services_selected"),
        icon: "error",
        });
        }
    
      // If all checks passed, show the appointment popup
      Swal.fire({
        title: t('create_appointment'),
        text: t('confirm_create_appointment'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('confirm'),
        cancelButtonText: t('cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          // Create the appointment
          const appointmentLength = cart.items.reduce(
            (total, item) => total + parseFloat(services.find(service => service._id === item.serviceId)?.length || 0),
            0
          );
          const appointmentItems = cart.items.map((item) => {
            const service = services.find((service) => service._id === item.serviceId);
            return {
              serviceId: service._id,
              price: service.price,
              length: service.length,
            };
          });
    
          const appointment = {
            email: user.email,
            date,
            time,
            length: appointmentLength,
            items: appointmentItems,
            status: "upcoming",
            paid: false,
            location
          };
    
          fetch("https://signatureauto-backend.herokuapp.com/appointments", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(appointment),
          })
          .then((response) => response.json())
          .then((data) => {
            Swal.fire({
              title: t('apt_created'),
              text: t('apt_confirm'),
              icon: t('success'),
            }).then((result) => {
              if (result.isConfirmed) {
                emptyCart(user.email);
              }
            });
          })
          .catch((error) => console.log(error));
        }
      });
    };
    
    function handlePayment() {
      const form = document.createElement('form');
      form.setAttribute('action', `https://signatureauto-backend.herokuapp.com/payment/${user.email}`);
      form.setAttribute('method', 'POST');
    
      const emailInput = document.createElement('input');
      emailInput.setAttribute('type', 'hidden');
      emailInput.setAttribute('name', 'email');
      emailInput.setAttribute('value', user.email);
    
      form.appendChild(emailInput);
      document.body.appendChild(form);
      form.submit();
    }
    
    if (isLoading) {
      return  <div className='admin-panel'>
      <div className="admin-panel-main">
      <p>{t('loading')}</p>
      </div>
     </div>;
    }

    if (!isLoading && !user) {
      loginWithRedirect();
      return null;
    }
  


    return (
      <div>
          <div>
            <Nav />
            <div className="cart-panel">
              <div className="row mb-4">
                <div className="col-10">
                  <h3 className="adminTitle">{t('cart')}</h3>
                </div>
                <div className='col-2'>
            <span className="emptybtn" onClick={handleEmptyCart}>
                {t('empty_cart')}
              </span>
              </div>
              </div>
              {cart.items && services.length >= cart.items.length && (
                <div className="list-group">
                  <ul>
                    {cart.items.map((item, index) => (
                      <li
                        className="list-group-item list-group-item-action mt-2"
                        key={index}
                      >
                        <div className='row'>
                          <div className='col-5'>
                            <img
                              className='cartimg'
                              alt='service'
                              src={services[index].image}
                            />
                          </div>
                          <div className='col-6'>
                            <div className='adminlist'>
                              {
                                i18n.language === 'en'
                                  ? services[index].service_name_en
                                  : services[index].service_name_fr
                              }
                            </div>
                            <div className='adminlist'>${parseInt(item.price)}</div>
                            <div className='adminlist'>{parseFloat(services[index].length)} {t('hrs')}</div>
                          </div>
                          <div className='col-1'>
                            <div className='admin-icons'>
                              <div className='admin-icon'>
                                <FaTrashAlt
                                  onClick={() => handleDelete(item.serviceId)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                    <div >
                          <span className="insertbtn">Total: ${total}</span>
            </div>
            
                  </ul>
                </div>
                
                
              )}
              <div className='apt-panel'>
          <h2 className="adminTitle2">{t('create_apt')}</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <br />
        <label className='cart_form'>
          Date:
          <input
            type="date"
            className="input_cart"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </label>
        <br />
        <label className='cart_form'>
          {t('time')}: 
          <input
            type="time"
            value={time}
            className="input_cart"
            onChange={(e) => setTime(e.target.value)}
          />
        </label>
        <br />
        <label className='cart_form'>
         {t('len')}:
          <input
            type="number"
            className="input_cart"
            value={length}
            onChange={(e) => setLength(e.target.value)}
            disabled={true}
          />
        </label>
        <div className='row'>
        <label  className='cart_form'>
  {t('location')}:
  <select className="input_cart mt-3" value={location} onChange={(e) => setLocation(e.target.value)}>
    <option value="home">{t('home_location')}</option>
    <option value="shop">{t('shop')}</option>
  </select>
</label>
</div>
<div className='radio'>
                  <label className="radio-label">
                    <input type="radio" value="paying" name="checkout" checked={checkoutOption === "paying"} onChange={(e) => setCheckoutOption(e.target.value)} />
                    {t('checkout_with_paying')}
                  </label> <br/>
                  <label className="radio-label">
                    <input type="radio" value="not-paying" name="checkout" checked={checkoutOption === "not-paying"} onChange={(e) => setCheckoutOption(e.target.value)} />
                    {t('checkout_without_paying')}
                  </label>
                </div>
        <br />

        <button
                  type="button"
                  className="animated-checkout-button mt-2 col-3"
                  onClick={checkoutOption === "paying" ? handlePayment : handleCreateAppointment}
                >
                  {t('create')}
                </button>
      </form>
      </div>
          </div>
          
          </div>
          </div>
    );
    
}

export default CartList;