import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';

function Gallery(){

    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    
      const { t } = useTranslation();

    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      initialSlide: 0,

    };

    const [gallery, setGallery] = useState([]);
    



    const getGallery = async () => {
        const response = await fetch("https://signatureauto-backend.herokuapp.com/gallery");
        const FinalData = await response.json();
        setGallery(FinalData)
    }



    useEffect(() => {
        getGallery();
    }, [])


    return (
        
        <div className='background-1'>
             <div className="title">{t('gallery_title')}</div>
            <div className='App'>
            <Slider {...settings} className="slider2">
            {gallery.map((item, index) => (
                    <div className="container-gallery" key={index}>
                        <div className='card_item'>
                        <div className='card_inner'>
                            <div className='row'>
                                <div className='col'>
                            <img src={item.before_img} className="serviceimg-gallery" alt=""/>
                            <div className='gallery-txt'>{t('before')}</div>
                            </div>
                            <div className='col'>
                            <img src={item.after_img} className="serviceimg-gallery" alt=""/>
                            <div className='gallery-txt'>{t('after')}</div>
                            </div>
                            </div>
                            <h1 className="name-gallery mt-3">{i18n.language === 'en' ? item.service_name_en : item.service_name_fr}</h1>
                                        
                        </div>
                        </div>
                    </div>
                ))}
                </Slider>
        </div>
        </div>
    

    );
    

}
export default Gallery;