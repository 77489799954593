import React from 'react';
import Nav from "../Main/Navbar";
const PaymentSuccess = () => {
  return (
    <div className='background-1'>
        <Nav/>
    <div className="payment-success-container">
      <h1>Payment Successful!</h1>
      <p>Thank you for your payment. Your transaction has been completed, and a receipt has been sent to your email address.</p>
    </div>
    </div>
  );
};

export default PaymentSuccess;