import React, { useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import logo from "../../assets/whitelogo.png";
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n'

import LoginButton from '../auth/login';
import LogoutButton from '../auth/logout';

const Navbar = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };


  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    Cookies.set('language', language, { path: '/' });
  };

  return (
    <nav className="navbar">
      <Link to="/" className="nav-item">
        <img className="logo-nav" src={logo} alt="logo" />
      </Link>
      <div onClick={handleClick} className="nav-icon">
        {open ? <FiX /> : <FiMenu />}
      </div>
      <ul className={open ? 'nav-links active' : 'nav-links'}>
       
        <li className="nav-item">
          <LoginButton />
        </li>
        <li className="nav-item">
          <LogoutButton />
        </li>
        
        <li className="nav-item1">
          <div className="language-selector" role="button">
            {t('code')}
            <ul className="language-dropdown">
              <li className="language-option" onClick={() => handleLanguageChange('fr')}>
                {t('french')}
              </li>
              <li className="language-option" onClick={() => handleLanguageChange('en')}>
                {t('english')}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
