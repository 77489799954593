import React, { useEffect, useState } from 'react';
import './Admin.css';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import withAdminAccess from './withAdminAccess';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Cookies from 'js-cookie';
import { FaArrowDown, FaArrowUp } from "react-icons/fa";



function MyCalendar() {

    const [language] = useState(Cookies.get('language') || 'fr');
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language])
    
      const { t } = useTranslation();

        const localizer = momentLocalizer(moment);
        const [events, setEvents] = useState([]);
        const [canceledAppointments, setCanceledAppointments] = useState([]);
        const [selectedEvent, setSelectedEvent] = useState(null);
        const [isExpanded, setIsExpanded] = useState(false);

      
        useEffect(() => {
          fetch('https://signatureauto-backend.herokuapp.com/appointments')
            .then(response => response.json())
            .then(appointments => {
              const calendarEvents = [];
              const canceledAppointments = [];
              appointments.forEach(appointment => {
                const start = new Date(appointment.date);
                const [hour, minute] = appointment.time.split(':');
                start.setHours(hour, minute, 0, 0);
                const end = new Date(start);
                end.setMinutes(start.getMinutes() + (appointment.length * 60));
                const event = {
                  start,
                  end,
                  title: appointment.email,
                  info: appointment // save the entire appointment object as an event property
                };
                if (appointment.status === 'canceled') {
                  canceledAppointments.push(appointment);
                } else {
                  calendarEvents.push(event);
                }
              });
              setEvents(calendarEvents);
              setCanceledAppointments(canceledAppointments);
            })
            .catch(error => console.error(error));
        }, []);
      
        const handleSelectEvent = event => {
          console.log('Selected event:', event);
          setSelectedEvent(event);
        };
      
        const handleClose = () => setSelectedEvent(null);

        const handleCancelAppointment = async () => {
          if (window.confirm(t('apt_cancel'))) {
            const appointmentId = selectedEvent.info._id;
            const response = await fetch(`https://signatureauto-backend.herokuapp.com/appointments/${appointmentId}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                status: 'canceled'
              })
            });
            if (response.ok) {
              const updatedAppointment = await response.json();
              console.log('Appointment canceled:', updatedAppointment);
              setSelectedEvent(null);
              setEvents(events.filter(event => event.info._id !== appointmentId));
              setCanceledAppointments([...canceledAppointments, updatedAppointment]);
            } else {
              console.error(t('apt_fail'), response.status, response.statusText);
            }
          }
        };
        
        const handleExpandCanceledAppointments = () => {
          setIsExpanded(!isExpanded);
        };
        
        return (
          <div className='admin-panel'>
            <Topbar />
            <div className='sidebar-container'>
              <Sidebar />
            </div>
            <div className='admin-panel-main'>
              <Calendar
                events={events}
                startAccessor='start'
                endAccessor='end'
                titleAccessor='title'
                localizer={localizer}
                views={['month']}
                defaultView='month'
                onSelectEvent={handleSelectEvent}
                style={{ height: '800px' }}
                messages={{
                  today: t('today'),
                  previous: t('last'),
                  next: t('next')
                }}
              />
      
      {canceledAppointments.length > 0 && (
  <div>
    <h2 className="adminTitle mt-2" onClick={handleExpandCanceledAppointments}>
      Canceled Appointments {isExpanded ? <FaArrowUp/> : <FaArrowDown/>}
    </h2>
    {isExpanded && (
      <ul>
        {canceledAppointments.map(appointment => (
          <li key={appointment._id}>
            {moment(appointment.date).format('MMMM Do YYYY')} at {appointment.time}: {appointment.email}
          </li>
        ))}
      </ul>
    )}
  </div>
)}

      <Modal show={selectedEvent !== null} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>{t('apt_details')}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p><strong>{t('email_address')}</strong> {selectedEvent?.info?.email}</p>
    <p><strong>Date:</strong> {moment(selectedEvent?.start).format('MMMM Do YYYY')}</p>
    <p><strong>{t('time')}</strong> {moment(selectedEvent?.start).format('h:mm a')}</p>
    <p><strong>{t('len')}</strong> {selectedEvent?.info?.length} {t('hrs')}</p>
    <p><strong>Status: </strong> {selectedEvent?.info?.status}</p>
    <p><strong>Paid: </strong> {selectedEvent?.info?.paid ? 'Yes' : 'No'}</p>
    {selectedEvent?.info?.status !== 'canceled' && (
      <Button variant="danger" onClick={handleCancelAppointment}>
        Cancel Appointment
      </Button>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

            </div>
          </div>
        );
      }
      
      export default withAdminAccess(MyCalendar);
      