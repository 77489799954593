import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/locale/en/translation.json';
import translationFR from './assets/locale/fr/translation.json';

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translationEN
      },
      fr: {
        translation: translationFR
      }
    },
    lng: 'fr', // set the default language to French
    fallbackLng: 'fr', // fallback to French if the preferred language is not available
    debug: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false
    }
  });

export default i18next;