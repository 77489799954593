import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Nav from "../Main/Navbar";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "../CSS/ContactUs.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../../i18n';

function ContactUs() {
  const [language] = useState(Cookies.get('language') || 'fr');
  useEffect(() => {
      i18n.changeLanguage(language);
  }, [language])
  
    const { t } = useTranslation();

  const form = useRef();
  const { getAccessTokenSilently } = useAuth0();
  const [message, setMessage] = useState("");
  const [user_name, setUser_name] = useState("");
  const [user_email, setUser_email] = useState("");
  const [phone, setPhone] = useState("");

  function sendEmail(e) {
    e.preventDefault();
    getAccessTokenSilently().then((accessToken) => {
      emailjs
        .sendForm(
          "service_67nphi7",
          "template_1o8hn4v",
          form.current,
          "h9988i6d1TDSraeIw",
          {
            Authorization: `Bearer ${accessToken}`,
            from_name: user_name,
            from_email: user_email,
            from_phone: phone,
          }
        )
        .then(
          (result) => {
            console.log(result.text);
            setMessage("Email sent!");
          },
          (error) => {
            console.log(error.text);
            setMessage("Error sending email.");
          }
        );
    });
  }

  function handleValidation(e) {
    const formInputs = form.current.elements;
    const phoneRegex = /^\d+$/;

    // Check that all inputs are valid except phone number
    for (let i = 0; i < formInputs.length; i++) {
      const input = formInputs[i];
      if (
        input.type !== "tel" &&
        input.type !== "submit" &&
        !input.validity.valid
      ) {
        input.classList.add(styles.invalid);
        e.preventDefault();
      } else {
        input.classList.remove(styles.invalid);
      }
    }

    // Check that phone number is valid if it exists
    const phoneInput = formInputs.namedItem("phone");
    if (phoneInput && !phoneRegex.test(phoneInput.value)) {
      phoneInput.classList.add(styles.invalid);
      e.preventDefault();
    } else if (phoneInput) {
      phoneInput.classList.remove(styles.invalid);
    }
  }

  return (
    <>
      <Nav />
      <div className={styles.container}>
        <h1 className="adminTitle">{t('contact')}</h1>
      </div>
      <div className={styles.ContactUs}>
        <form ref={form} onSubmit={sendEmail} onInvalid={handleValidation}>
        <label className="radio">{t('name')}</label>
          <input
            type="text"
            name="user_name"
            required
            placeholder= {t('name')}
            onChange={(e) => setUser_name(e.target.value)}
          />
          <label className="radio">{t('email_c')}</label>
          <input
            type="email"
            name="user_email"
            required
            placeholder={t('email_c')}
            onChange={(e) => setUser_email(e.target.value)}
          />
          <label className="radio">{t('number_c')}</label>
          <input
            type="tel"
            name="phone"
            required
            placeholder={t('number_c')}
            onChange={(e) => setPhone(e.target.value)}
          />
           <label className="radio">Message</label>
          <textarea name="message" required />
          <input type="submit" value={t('send')} className={styles.submitButton} />
        </form>
        <div className={styles.message}>{message}</div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <a
            href="https://instagram.com/signature_auto_elegance?igshid=YmMyMTA2M2Y="
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <FontAwesomeIcon icon={faInstagram} size="3x" />
          </a>
          <a
            href="
            https://www.facebook.com/SignatureAutoDetailling?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} size="3x" />
          </a>
        </div>
      </footer>
    </>
  );
}

export default ContactUs;
